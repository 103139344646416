import { render, staticRenderFns } from "./home.html?vue&type=template&id=618ad179&scoped=true&"
import script from "./home1.js?vue&type=script&lang=js&"
export * from "./home1.js?vue&type=script&lang=js&"
import style0 from "./home1.less?vue&type=style&index=0&id=618ad179&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "618ad179",
  null
  
)

export default component.exports