import Vue from 'vue'
import App from './App.vue'
import './styles'
import './plugins'
import element from 'element-ui'
// import $ from 'jquery'
import 'element-ui/lib/theme-chalk/index.css'
// import animated from 'animate.css'
Vue.config.productionTip = false // npm install animate.css --save安装，在引入
import router from "./router"
import '@/utils/rem.js'
// import 'swiper/swiper-bundle.min.css'
// import 'swiper/swiper.min.css'


// Vue.use(animated)
Vue.use(element)
// Vue.prototype.$ = $ // 当然还有这句话 给vue原型上添加 $
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
