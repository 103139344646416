import Swiper from "swiper"

export default {
    // components: {
    //     Swiper
    // },
    props: [],
    name: 'homePage',
    data() {
        return {
            noticeVisible: true,
            productActive: 0,
            productList: [{
                key: 0,
                title: "智能投后管理"
            }, {
                key: 1,
                title: "智能贷后管理"
            }, {
                key: 2,
                title: "智能债后管理"
            }],

            imgList: [
                {
                    key: 0,
                    url: require('../../assets/h1.png'),
                    title: "整合业务信息，提供线上检查管理，作业管理的定制化系统工具"
                },

                {
                    key: 1,
                    url: require('../../assets/h2.png'),
                    title: "整合业务信息，提供线上检查管理，作业管理的定制化系统工具"
                },
                {
                    key: 2,
                    url: require('../../assets/h3.png'),
                    title: "整合业务信息，提供线上检查管理，作业管理的定制化系统工具"
                },
            ],
            showImgList: [],
            // activeImg: 0,
        }
    },
    created() {
    },
    mounted() {
        this.showImgList = this.imgList

    },
    methods: {

        choosePro(obj, index) {
            // debugger
            this.productActive = obj.key
            this.$refs.carousel.setActiveItem(index);
        },




    }
}
