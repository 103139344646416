import axios from 'axios'
import { Message } from 'element-ui'
// import Swiper from 'swiper'
// import 'swiper/dist/css/swiper.css'
import $ from 'jquery'


export default {
    // components: {
    //   Swiper
    // },
    props: [],
    name: 'homeHeader',
    data() {
        return {
            menuList: [
                { key: 1, index: 'home', title: "首页" },
                { key: 2, index: 'product1', title: "产品" },
                { key: 3, index: 'service', title: "服务与支持" },
                { key: 4, index: 'about', title: "关于我们" }],
            activeIndex: '',
            clickIndex: '',
            secondMenuList: [
                {
                    key: 2 - 1, title: "智能投后管理",
                    children: [
                        { key: 2 - 1 - 1, title: "债检-悦审版", index: 'product', url: "https://zjhx.datayell.cn/" },
                        { key: 1, title: "债检-智享版", url: "https://hf.datayell.cn/#/login" }],

                },
                // { key: 2 - 2, title: "智能贷后管理" },
            ],
            activeSec: 0,
            activeThird: null,
            showSec: false

        }
    },
    watch: {
        $route() {
            console.log("=====activeMenu1=", sessionStorage.getItem('clicKMenu'))
            if (sessionStorage.getItem('clicKMenu')) {
                this.clickIndex = sessionStorage.getItem('clicKMenu')
            }

        }
    },
    created() {
    },
    mounted() {
        let path = this.$route.path



    },
    methods: {
        //点击跳转
        onMenuClick(index) {
            this.clickIndex = index == 'product' ? 'product1' : index
            let showSec = this.showSec
            //sessionStorage.setItem('clicKMenu', '')
            // debugger
            if (index !== 'product1') {
                this.$router.push('/' + index);
                this.showSec = false
                sessionStorage.setItem('clicKMenu', this.clickIndex)

            } else {
                this.showSec = !showSec
            }
        },

        //鼠标移入一级菜单
        handleMenuOver(index) {
            this.activeIndex = index
            if (index == 'product1') {
                this.showSec = true
            }
            else {
                this.showSec = false
            }

        },
        //鼠标移出
        handleMenuLeave() {
            this.showSec = false
            this.activeIndex = null
        },
        //二级移入
        handleSecOver() {
            //this.showSec = true
        },
        //二级菜单移入
        handleSecMenuOver(index) {
            this.activeSec = index
        },
        clickSecMenu(url) {
            window.open(url, '_blank');
        },
        handleThirdMenuOver(index) {
            this.activeThird = index
        },



    }
}
