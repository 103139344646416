<template>
  <div>
    <div id="app" :class="noticeVisible?'app':''">
      <HomeHeader></HomeHeader>
      <div class="main">
        <router-view></router-view>
      </div>
      <HomeFooter></HomeFooter>
    </div>
    <div class="mask" v-if="noticeVisible"></div>
    <div class="notice" v-if="noticeVisible">
      <p> 尊敬的用户： </p>
      <p> 感谢您对投后数字化管理平台[债检助手]的关注和认可，为了有更好的用户体验，平台于2024年1月15日进行系统升级。 </p>
      <p> 系统升级后，将无法访问系统原登陆地址。</p>
      <p> 请访问并保存最新登陆地址 <a style="text-decoration:none;" target="_blank" href="https://zjhx.datayell.cn/">
          https://zjhx.datayell.cn/；</a>
      </p>
      <p> 或依次点击此网站<a> 产品菜单-投后产品-债检悦审版；</a> </p>
      <p> 系统升级给您带来的不便，我们深表款意。 </p>
      <div class="btn">
        <el-button type="primary" size="medium" @click="showNotice" round>我知道了</el-button>

      </div>
    </div>
  </div>
</template>

<script>
  import Home from './views/home/index.vue'
  import HomeHeader from '@/components/home-header/index.vue'

  import HomeFooter from '@/components/home-footer/index.vue'
  import ZoomRatio from '@/utils/zoom.js'

  export default {
    name: 'App',
    components: {
      Home,
      HomeHeader,
      HomeFooter
    },
    data() {
      return {
        noticeVisible: false
      }
    },

    created() {
      new ZoomRatio().init()
    },
    mounted() {
      // document.querySelector('body').setAttribute('style', 'overflow:hidden')
    },
    methods: {
      showNotice() {
        this.noticeVisible = !this.noticeVisible
        document.querySelector('body').setAttribute('style', 'overflow:auto')

      },
    }


  }

</script>

<style lang="less">
  .menu1 {
    width: 400px !important;
    top: 160px !important;
    left: 200px !important;
    z-index: 2001;

  }

  .menu2 {
    width: 1200px !important;


  }

  .el-menu.el-menu--popup.el-menu--popup-bottom-start {
    padding: 25px 47px;
    width: 300px;
    margin-top: 18px;
    background: rgb(244, 246, 252)
  }

  .el-submenu__title {
    font-size: 12px;
    margin-bottom: 12px;
    background: rgb(244, 246, 252) !important
  }

  .el-menu-item {
    font-size: 12px;
    margin-bottom: 12px;
  }


  .el-menu--popup-right-start {
    margin-left: 50px !important;
    margin-top: -22px !important;
    padding: 25px 47px !important;
    height: 94px;

  }

  .app {
    overflow: hidden;

  }

  .notice {
    background: url("./公告背景.png");
    background-size: 100% 100%;
    width: 650px;
    height: 800px;
    padding: 350px 60px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;

    p {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 20px;
      line-height: 30px;

      a {
        color: rgb(106, 158, 241);
      }
    }

    .btn {
      text-align: center;
    }
  }



  .mask {
    position: fixed;
    /* 使遮罩层铺满整个页面 */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(54, 52, 52, 0.5) !important;
    /* 通过z-index属性，使遮罩层在弹出层之下 */
    z-index: 888;
    overflow: hidden;
  }
</style>