import Vue from 'vue'

class Kf {
  constructor () {
    this.kfConfig = {
      sign: 'TEwNTMTU3MI3NjEwNjQzMzU0Nzg4MDA3NzIxOTEwMTg=',
      arg: '10191018',
      style: 3,
      windoStyle: 'height=780,width=860,left=50,status=no,toolbar=no,menubar=no,resizable=yes,scrollbars=no,location=no,titlebar=no'
    }
  }

  a = document.createElement('a')
  open () {
    this.a.setAttribute('onclick', 'openJesongChatByGroup(34356, 48634);return false')
    this.a.click()
    document.getElementById('jesong_chat_max_btn').click()
    // window.open('https://tb.53kf.com/code/stat/9513709dbb116b8b5b1259c71548778c4/3')
    window.open(`https://www26.53kf.com/webCompany.php?kf_sign=${this.kfConfig.sign}&arg=${this.kfConfig.arg}&style=${this.kfConfig.style}`, '_blank', this.kfConfig.windoStyle)
  }
}

const kf53 = {}
kf53.install = function (Vue, options) {
  Vue.prototype.$kf = new Kf()
}

Vue.use(kf53)
