export default {
  components: {},
  props: [],
  name: 'homeFooter',
  data() {
    return {}
  },
  computed: {
    // x: function() {}
  },
  watch: {
    // x: function(val) {}
  },
  created() { },
  mounted() { },
  destroyed() { },
  methods: {
    toMenu(index) {
      sessionStorage.setItem('clicKMenu', index)
      this.$router.push('/' + index);
    },
  }
}
