import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)

export const constantRoutes = [

    {
        path: "/",
        redirect: "/home",


    },
    {
        path: "/home",
        component: () => import("@/views/home/index"),
        meta: { title: "首页" },

    },
    {
        path: "/product",
        component: () => import("@/views/product/index"),
        name: "Product",
        hidden: true,
        meta: { title: "产品" },
    },
    {
        path: "/service",
        component: () => import("@/views/service/index"),
        name: "Service",
        hidden: true,
        meta: { title: "服务与支持" },
    },
    {
        path: "/about",
        component: () => import("@/views/about/index"),
        name: "About",
        hidden: true,
        meta: { title: "关于我们" },
    },
    { path: "*", redirect: "/home", hidden: true },

]



const createRouter = () =>
    new Router({
        // mode: 'history', // require service support
        scrollBehavior: () => ({ y: 0 }),
        routes: constantRoutes,
    })

const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router
